export const LoremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"

////////// CV //////////
export const TitleFormationAcademique = "Formation académique";
export const TitleMathematiques = "Mathématiques";
export const TitleIngenerieInformatique = "Ingénierie informatique";
export const TitleManagement = "Gestion";

export const TextFormationAcademique = "";
export const TextMathematiques = "J'ai dabord débuté un baccalauréat en mathématique pure à l'université de Montréal. Cette formation a été fort utile a divers niveaux. Tout d'abord, les mathématiques ont développé mon raisonnement logique et ma capacité à résoudre des problèmes complexes. De plus, cette formation m'a permis d'acquérir plus rapidement certaines compétences dans des domaines plus complexe de l'ingénierie informatique, comme l'intelligence artificielle et l'algorithmie."
export const TextIngenerieInformatique = "J'ai obtenu mon diplôme en ingénierie informatique à Polytechnique Montréal avec une concentration en intelligence artificielle et science des données en 2021. J'ai obtenu une mention d'excellence pour mes résultats académiques. Mon parcours m'a permis de me familiariser avec de nombreux langages de programmation comme le Python, le C# et plus particulièrement le C++. J'ai travaillé sur différents types de projets durant mon parcours, notamment les systèmes temps réels, les blockchains, les applications web et mobiles, l'intelligence artificielle, l'algorithmie et bien d'autres";
export const TextManagement = "je poursuis présentement un diplome d'étude supérieur spécialisé (DESS) en management au HEC dans le but de me développer davantage. Cette formation nous apprend à nous connaitre comme personne et comme gestionnaire. On nous donne également l'opportunité de nous exercer dans différentes situations de gestion.";

////////// Portfolio //////////
export const TextSiteWeb = "Dans le but d'approfondir mes connaissances en développement web, j'ai choisi de créer ce petit site web qui me sert de portfolio. Ainsi, j'ai acquis des compétences en javascript et React, et je me suis lancé dans ce projet. Ayant utilisé Figma par le passé pour concevoir des applications, j'ai commencé par esquisser le type de site web que je souhaitais développer. Bien que je ne sois pas un designer professionnel, j'attache de l'importance à ce que mes projets soient esthétiques, intuitifs et faciles à utiliser. Je me tiens régulièrement informé des tendances en design pour enrichir mes compétences dans ce domaine.";
export const TextPokemon = "J'ai travaillé à plusieurs reprises avec Unity et Unreal, aussi bien par plaisir que dans le cadre de compétitions informatiques. Récemment, j'ai décidé de relever le défi de programmer un jeu dans le style de Pokemon. Mon objectif était simplement de me remettre à jour dans le domaine du développement de jeux vidéos et de pousser mes connaissances un peu plus loins. Par exemple, j'ai créé des animations, des menus, etc. J'ai même conçu mon propre pixel art en utilisant le logiciel Aseprite.";
export const TextAppMobile = "L'association Montréal Gaymers dispose de quelques outils technologiques pour faciliter la gestion de leurs évènements. Cependant, ces outils sont plutôt minimaliste. J'ai donc proposé de les mettre à jour. J'ai développé une nouvelle application mobile en utilisant React Native, et j'ai déployé un API REST sur un Raspeberry Pi pour stocker les données.";
export const TextImageRecognition = "Dans le cadre d'un cours d'intelligence artificielle, nous avons participé à un concours amical de reconnaissance de formes. En utilisant les connaissances acquises en classe, nous devions créer un modèle capable d'analyser une image et de reconnaître des formes. Avec mon équipe, nous avons opté pour un modèle de type CNN (réseau neuronal convolutif). Nous avons généré des données d'entraînement supplémentaires en apportant de légères modifications aux images fournies, puis nous avons entraîné notre modèle. Ce travail nous a valu la 7e place dans le concours.";
export const TextBlockchain = "Comme projet intégrateur de 3e année, nous devions concevoir un système informatique permettant aux compagnies et aux professeurs de partager les résultats scolaires des étudiants, le tout basé sur la technologie des chaînes de blocs (blockchain). Nous devions mettre au point une application Android pour les utilisateurs, une application PC pour le compte admin, un serveur et 3 mineurs. Pour ce projet, nous devions passer à travers toutes les étapes du développement informatique, c'est à dire l'évaluation des requis du client, choisir les meilleurs technologies pour répondre aux besoins, écrire une reponse à l'appelle d'offres, créer des tâches et un échéancier, rédiger des rapports à chaque sprint, développer les différents systèmes et fournir un rapport final.";
export const TextWater = "";
export const TextPathFinding = "";


////////// Clients //////////
export const TextLiggo = "Liggo est une plateforme de gestion de la production manufacturière qui connecte les utilisateurs, les systèmes et la machinerie. En tant que développeur logiciel chez liggo, j'ai contribué à la conception de nouvelles fonctionnalité et au maintient de celles existante, principalement en C#";
export const Dot1Liggo = "Réparation d'une partie cruciale de l'application en démontrant que le flux de travail, tel que défini par la documentation, ne correspondait pas au fonctionnement réel de l'application"
export const Dot2Liggo = "Amélioration de l'expérience utilisateur en uniformisant l'interface graphique, en modernisant le style de l'application et en rendant son utilisation plus intuitive"
export const Dot3Liggo = "Participation à la clarification des besoins du client en assistant le chef de projet dans la rédaction des spécifications, la planification des sprints et des échéanciers"

export const TextMtlGaymers = "Montréal Gaymers est une association d'évènements de jeux vidéos. Chaque mois, les administrateurs organisent des compétitions de jeux vidéos pour les membres avec des prix à gagner. Mon rôle en tant que trésorier du conseil d'administration est de m'assurer de la bonne gestion des finances, d'aider à l'organisation des évènements et de représenter l'association lors de divers évènements."
export const Dot1MtlGaymers = "Changement de la forme juridique de l'entreprise, la faisant passer d'une association à une OBNL"
export const Dot2MtlGaymers = "Réduction de près de 50% les charges administratives en réévaluant les besoins de l'association concernant certaines technologies"
export const Dot3MtlGaymers = "Professionnalisation de l'association. Rédaction de procès verbaux, de rapports financiers et des règlements administratifs, ainsi que travaillé à l'amélioration de l'image de marque"

export const TextPositron = "Chez Positron Access, j'ai travaillé au développement du produit GAM, un appareil permettant de fournir un internet haute vitesse sans avoir besoin de la fibre optique. Mon travail à été principalement de faire en sorte que les différents composant du système embarqué communiquent efficacement entre eux."
export const Dot1Positron = "Traitement des données entrant avec le processus de TDM"
export const Dot2Positron = "Apprentissage du protocole PON"
export const Dot3Positron = "Utilisation de MQTT pour la communication entre différents composants"

export const TextTelcobridges = "Telcobridges offre des logiciels et du matériel de télécommunication de class opérateur. Des appareils répondant aux besoins de session border controller, de VoIP gateway, de surveillance du réseau et plus encore. J’ai développé principalement en C/C++, mais également en Ruby et en Python pour la page web et les scripts de tests, entre autres."
export const Dot1Telcobridges = "Embauche et intégration de 2 stagiaires et 1 nouvel employé, ainsi que la création de séances de formation pour facilité la compréhension du produit."
export const Dot2Telcobridges = "Mise en place des échéanciers, des objectifs et de la répartition des tâches lors d'un projet de développement d'une nouvelle technologie"
export const Dot3Telcobridges = "Maintenance de l'application en participant à la résolution de différents bugs et au développement de nouvelles fonctionnalité principalement en C++"

export const TextAEP = "Élu comme conseillé au sein du conseil central de l’association étudiante de Polytechnique, mon rôle est de prendre connaissance des dossiers touchants la communauté étudiante et de conseiller les membres du comité de coordination sur les démarches à suivre"
export const Dot1AEP = "Donnez des opinions éclairées sur différents enjeux"
export const Dot2AEP = "Respecter le code de procédure lors d’instances officielles"
export const Dot3AEP = "Être autodidacte et se renseigner sur les enjeux de la communauté étudiante"

export const TextCEGInfo = "Je me suis longtemps impliqué au sein du comité étudiant en génie informatique (CEGInfo). Élu d'abord comme vice-président à l'éducation (VP-éduc) et ensuite comme président, j'ai défendu les intérêts des étudiants et j'ai organisé de nombreuse activités avec les autres membres du comité."
export const Dot1CEGInfo = "Mise en place de réseau de communication efficace afin de bien communiquer aux étudiants la démarche à suivre au début de la crise de la covid-19"
export const Dot2CEGInfo = "Représenter les intérêts des étudiants en génie informatique au sein de différentes instances"
export const Dot3CEGInfo = "Coordonner le travail des membres du comité"

